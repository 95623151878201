<template>
  <section class="interactive-wrap">
    <div class="interactive-content" v-if="isPc">
      <div class="interactive-content__left">
        <img src="./img/logo.png" alt="logo" width="105" />
        <p class="logo-title">微吼·云会议</p>
      </div>
      <div class="interactive-content__right">
        <p>欢迎使用微吼·云会议</p>
        <el-button class="join" @click="join('join')">加入会议</el-button>
        <el-button class="login" @click="join('login')">登录</el-button>
      </div>
    </div>
    <div class="interactive-content__mobile" v-if="isMobile">
      <div class="interactive-content__header">
        <p class="content-title">欢迎使用微吼·云会议</p>
      </div>
      <div class="interactive-content__center">
        <img src="./img/logo-red.png" alt="logo"/>
        <p class="interactive-title">微吼·云会议</p>
      </div>
      <div class="interactive-content__footer">
        <el-button class="join" @click="join('join')">加入会议</el-button>
        <el-button class="login" @click="join('login')">登录</el-button>
      </div>
    </div>
    <el-dialog :visible.sync="downloadDialogVisible" title="提示" center>
      <span>还未下载客户端，是否立即下载</span>
      <footer>
        <el-button class="download-btn" @click="downloadClient">下载</el-button>
      </footer>
    </el-dialog>
  </section>
</template>

<script>
import protocolCheck from './protocolcheck'
export default {
  data() {
    return {
      downloadDialogVisible: false,
      prefix: '',
      id: '',
      isPc: true,
      isMobile: false
    }
  },
  created() {
    if ((navigator.userAgent.toLowerCase().match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
      this.isMobile = true
      this.isPc = false
    } else {
      this.isMobile = false
      this.isPc = true
    }

    const route = this.$route.params
    if (route.prefix && route.id) {
      this.prefix = route.prefix
      this.id = route.id
    }
    this.query = this.$route.fullPath.split('?')[1]
  },
  methods: {
    downloadClient() {
      const downloadUrls = {
        mac: `https:${process.env.VUE_APP_SDK_PATH}vhallyun-app-download/Vmeeting.pkg`,
        windows: `https:${process.env.VUE_APP_SDK_PATH}vhallyun-app-download/Vmeeting.exe`,
        ios: 'https://itunes.apple.com/cn/app/id1119902970',
        android: 'https://www.pgyer.com/Vmeeting'
      }
      const system = this.getSystem()
      window.location = downloadUrls[system]
    },
    join(type) {
      if (this.isWechat()) {
        alert('请在浏览器上打开')
      } else {
        const urls = {
          join: 'vhallinteractive://vhallyun.com/join',
          login: 'vhallinteractive://vhallyun.com/login'
        }
        let url = urls[type]
        if (this.id) {
          if (this.query) {
            url = `${url}/${this.id}?${this.query}`
          } else {
            url = `${url}/${this.id}`
          }
        }
        if (this.getSystem() === 'android') {
          window.location.href = url
          setTimeout(function() {
            let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden || window.document.webkitHidden
            if (typeof hidden === 'undefined' || hidden === false) {
              window.location.href = 'https://www.pgyer.com/Vmeeting'
            }
          }, 2000)
        } else if (this.getSystem() === 'ios') {
          window.location.href = url
          setTimeout(function() {
            let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden || window.document.webkitHidden
            if (typeof hidden === 'undefined' || hidden === false) {
              window.location.href = 'https://itunes.apple.com/cn/app/id1119902970'
            }
          }, 2000)
        } else {
          this.hasLocalClient(url, () => {
            this.downloadDialogVisible = true
          })
        }
      }
    },
    getSystem() {
      const ua = navigator.userAgent.toLocaleLowerCase()
      const macReg = /macintosh|mac os x/i
      const windowsReg = /windows|win32/i
      let system = 'mac'
      if (/iPad|iPhone|iPod/i.test(ua)) {
        system = 'ios'
      } else if (windowsReg.test(ua)) {
        system = 'windows'
      } else if (macReg.test(ua)) {
        system = 'mac'
      } else if (/android/i.test(ua)) {
        system = 'android'
      }
      return system
    },
    /**
     *
     * @param {string} url   唤起客户端的schema 协议
     * @param {fn} failcb 失败回调函数
     */
    hasLocalClient(url, failCb) {
      protocolCheck(url, function() {
        console.log('本地不存在客户端')
        failCb && failCb()
      })
    },
    isWechat() {
      let ua = navigator.userAgent.toLowerCase()
      return ua.indexOf('micromessenger') !== -1
    }
  }
}
</script>

<style lang="less" scoped>
.interactive-wrap {
  height: 100%;
  min-height: 100vh;
  text-align: center;
  background: #f7f7f7;
  .interactive-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 840px;
    height: 550px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 10px 29px 0px rgba(9, 44, 78, 0.1);
    border-radius: 0px 8px 8px 0px;
    overflow: hidden;
    &__left,
    &__right {
      width: 50%;
      height: 100%;
      flex: 1;
    }
    &__left {
      background: url('./img/bcg.png') no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      .logo-title {
        font-size: 28px;
        font-weight: 500;
        line-height: 40px;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-size: 18px;
        color: #000;
        line-height: 25px;
      }
      .el-button {
        width: 220px;
        height: 40px;
        border: 1px solid #fc5659;
        outline: none;
        border-radius: 20px;
        font-size: 14px;
        cursor: pointer;
        &.join {
          margin-top: 30px;
          color: #fff;
          background: #fc5659;
        }
        &.login {
          margin-top: 15px;
          color: #fc5659;
          background: #fff;
          margin-left: 0px;
        }
      }
    }
  }
  /deep/ .el-dialog {
    width: 400px;
    min-height: 200px;
    &__title {
      font-weight: bold;
    }
    &__body {
      text-align: center;
      color: #444;
    }
    .download-btn {
      width: 140px;
      height: 40px;
      margin-top: 30px;
      background: #fc5659;
      color: #fff;
      border-color: #fc5659;
      border-radius: 20px;
    }
  }
  .interactive-content {
    &__mobile {
      position: relative;
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      top: 0px;
      left: 0px;
    }
    &__header {
      height: 20%;
      .content-title {
        position: relative;
        font-size: 1.5rem;
        font-weight: 400;
        color: #999999;
        top: 70%;
      }
    }
    &__center {
      height: 40%;
      margin-top: 5%;
      .interactive-title {
        font-size: 2rem;
        font-weight: 500;
        color: #000000;
      }
    }
    &__footer {
      height: 40%;
      .el-button {
        width: 82%;
        height: 15%;
        border: 1px solid #FC5659;
        outline: none;
        border-radius: 22px;
        font-size: 18px;
        cursor: pointer;
        &.join {
          margin-top: 30px;
          color: #ffffff;
          background: #FC5659;
        }
        &.login {
          margin-top: 20px;
          color: #FC5659;
          background: #fff;
          margin-left: 0px;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .interactive-content {
    width: 1180px;
    height: 773px;
  }
}
</style>
